@import 'styles/system/variables';

.container {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  column-gap: 0.5rem;
  align-items: flex-end;
  .field {
    flex: 1;
  }
  .hint, .label {
    flex: 1 0 100%;
    white-space: pre-line;
  }
}
