@import 'styles/system/variables';

.container {
  display: flex;
  margin: $spacing--medium;
  align-items: center;
  flex: 1;
  min-height: 40px;
  column-gap: 0.75rem;
  & > * {
    margin-top: auto;
    margin-bottom: auto;
    &:first-child {
      margin-left: 0;
    }
  }
  &--justify-center {
    justify-content: center;
  }
  &--justify-left {
    justify-content: flex-start;
  }
  &--justify-right {
    justify-content: flex-end;
  }
  &--justify-space-between {
    justify-content: space-between;
  }
  &--collapsed {
    margin: $spacing--medium--collapsed;
  }
}
