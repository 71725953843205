@import 'styles/system/variables';

.copy-text {
  @include font--copy;
  color: $color--gray--darkest;
  &--medium {
    font-weight: $font-weight--medium;
  }
  &--secondary {
    color: $color--gray--dark;
  }
  &--reading-block {
    font-weight: $font-weight--thin;
  }
  &--highlight {
    font-family: $font-family--display;
    font-size: $font-size--highlight;
    font-weight: $font-weight--medium;
    line-height: $line-height--copy;
    color: $color--gray--darkest;
  }
  &--hint {
    font-size: $font-size--hint;
    color: $color--gray--dark;
  }
  &--error {
    font-size: $font-size--hint;
    color: $color--negative;
  }
  a {
    font-weight: $font-weight--bold;
    color: $color--accent;
    text-decoration: underline;
  }
  svg {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}
