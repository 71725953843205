@import 'styles/system/variables';

.header {
  min-height: 80px;
  display: flex;
  align-items: center;
  padding: 2rem;
  background: $color--white;
  border-bottom: $border--large;
  border-color: $color--primary--dark;
  margin-bottom: 1rem;
  &--collapsed {
    margin-bottom: 0;
  }
}

.text {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  &--capitalized {
    text-transform: capitalize;
  }
}
