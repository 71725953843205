@import 'styles/system/variables';

.display-text {
  @include font--display;
  max-width: $max-length--display;
  color: $color--gray--darkest;
  &--large {
    font-weight: $font-weight--regular;
    font-size: $font-size--display--large;
    line-height: $line-height--display--large;
  }
  &--small {
    font-weight: $font-weight--black;
    font-size: $font-size--display--small;
  }
  svg {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}
