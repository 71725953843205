@import 'styles/system/variables';

.togglebox {
  &__container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: $spacing--medium;
    display: flex;
    flex-flow: row wrap;
    gap: 2rem;
    &__item {
      border: $border--small;
      border-radius: $border-radius--medium;
      box-shadow: $shadow--small;
      white-space: nowrap;
      text-align: center;
      .icon {
        color: $color--primary;
        margin-top: 1rem;
      }
      &:hover {
        box-shadow: $shadow--medium;
        background-color: $color--primary--lightest;
        @include transition-in;
        cursor: pointer;
      }
      &:focus {
        background-color: $color--primary--lightest;
        @include transition-in;
       }
      &--active,
      &--active:hover {
        .togglebox__list__item {
          &__label {
            color: $color--primary--lightest;
          }
          &__main {
            background-color: $color--primary;
          }
        }
        .icon {
          color: $color--white;
        }
      }
      &--closed {
        display: none;
      }
      &--disabled,
      &--disabled:hover,
      &--disabled:focus {
        background-color: $color--gray--light;
        border-bottom: 2px solid $color--gray--light;
        cursor: not-allowed;
        box-shadow: $shadow--inset--small;
        .togglebox__list__item {
          &__label {
            color: $color--gray--dark;
          }
          &__main {
            background-color: $color--background--dark;
          }
        }
      }
      &__main {
        position: relative;
        min-height: 6rem;
        min-width: 14rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        background-color: $color--background;
        border-radius: $border-radius--medium $border-radius--medium 0 0;
        padding: 0.5rem;
      }
      &__label {
        padding: 1rem 0.5rem 0.5rem 0.5rem;
      }
      &__detail {
        padding: 0.5rem;
        border-top: $border--small;
      }
    }
  }
  &__body {
    position: relative;
    display: flex;
    overflow: hidden;
  }
  &__panel {
    width: 100%;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transform: translateX(20%);
    &--active {
      position: relative;
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
      @include transition-in;
    }
  }
}
