@import 'styles/system/variables';

.container {
  padding: $spacing--medium;
  &--secondary {
    background: $color--background;
    border: $border--small;
  }
  &--collapsed {
    padding: $spacing--medium--collapsed;
  }
}
