@import 'styles/system/variables';

.container {
  margin-bottom: 3em;
  min-width: 10vw;
  &--collapsed {
    margin-bottom: 0;
  }
}

.content {
  width: 100%;
  &--secondary {
    background: $color--background;
    border: $border--small;
  }
}
