@import 'styles/system/variables';

.tag {
  width: max-content;
  height: max-content;
  background-color: $color--brand;
  border-radius: $border-radius--large;
  padding: 0.125rem 1rem;
  box-shadow: $shadow--small;
  text-transform: lowercase;
  &__content {
    color: $color--brand--lightest;
    display: inline-flex;
    align-items: center;
  }
  &--interactable:hover {
    cursor: pointer;
    box-shadow: $shadow--medium;
  }
  .icon {
    margin-right: 0.5rem;
  }
}
