@import 'styles/system/variables';

.container {
  color: $color--gray--darkest;
  padding: $spacing--medium;
  max-width: $max-length--copy;
  &--collapsed {
    padding: $spacing--medium--collapsed;
  }
}
