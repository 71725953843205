@import 'styles/system/variables';

.list {
  &--loading {
    @include animate--shimmer;
  }
}

.list {
  border-bottom: $border--small;
  padding: 0;
  margin: 0;
  list-style: none;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: $border--small;
  background-color: $color--background;
  & > * {
    flex: 1;
  }
}
