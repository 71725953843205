@import 'styles/system/variables';
@import 'styles/system/partials/breakpoints';

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
  grid-gap: 0 16vw;
  &--four {
    grid-template-columns: 25% 25% 25% 25%;
    grid-gap: 50px 0;
    @include layout--medium {
      grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
    }
    @include layout--small {
      grid-template-columns: 1fr;
    }
  }
  @include layout--small {
    grid-template-columns: 1fr;
  }
}
