@import 'styles/system/variables';

$max-width--small: 250px;
$max-width--medium: 300px;
$max-width--large: 570px;

.container {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  min-height: 100px;
  margin-left: 2rem;
  &--small > *:not(button):not(span):not(svg):not(i):not(dialog) {
    max-width: $max-width--small;
    flex: 1 1 $max-width--small;
  }
  &--medium > *:not(button):not(span):not(svg):not(i):not(dialog) {
    max-width: $max-width--medium;
    flex: 1 1 $max-width--medium;
  }
  &--large > *:not(button):not(span):not(svg):not(i):not(dialog) {
    max-width: $max-width--large;
    flex: 1 1 $max-width--large;
  }
  & > span,
  & > svg {
    flex: 0 1 40px;
    text-align: center;
    margin: 3.2rem 0 0;
  }
  & > * {
    margin-top: 1rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
  & > [data-field-block-role="field--no-label"] {
    margin-top: 2.58rem;
    margin-bottom: 1rem;
  }
  & > [data-field-block-role="date-range"] {
    max-width: $max-width--medium;
  }
  & > [data-field-block-role="checkbox"],
  & > [data-field-block-role="radio"] {
    margin-top: 3.125rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    flex: 0 1 auto;
  }
  & > [data-field-block-role="switch"] {
    margin-top: 3rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    flex: 0 1 auto;
  }
  & > [data-field-block-role="button"] {
    margin-top: 2.5rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  &--collapsed > * {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 2rem;
  }
  &--collapsed > [data-field-block-role="checkbox"],
  &--collapsed > [data-field-block-role="radio"],
  &--collapsed > [data-field-block-role="switch"],
  &--collapsed > [data-field-block-role="button"] {
    margin-top: 0;
    margin-bottom: 0;
  }
}
