@import 'styles/system/variables';

@mixin disabled-styles {
  background: $color--gray--light;
  border-color: $color--gray--light;
  cursor: not-allowed;
  box-shadow: none;
  .text {
    color: $color--gray;
    fill: $color--gray;
  }
  &:hover {
    background: $color--gray--light;
    border-color: $color--gray--light;
    .text {
      color: $color--gray;
      fill: $color--gray;
    }
    &:active {
      box-shadow: none;
    }
  }
}

.button {
  display: flex;
  align-items: center;
  background: $color--background--dark;
  box-sizing: border-box;
  text-align: center;
  padding: 0.25rem 0;
  min-height: 38px;
  border-radius: $border-radius--medium;
  border: $border--medium;
  outline: none;
  cursor: pointer;
  transition: all 250ms $ease-in--quint;
  .text {
    padding: 2px 1em 0;
    display: flex;
    align-items: center;
    svg {
      margin: 0 0.5em;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &:hover {
    box-shadow: $shadow--medium;
  }
  &:disabled {
    @include disabled-styles;
  }
  &:focus {
    outline: none;
  }
  &:focus-visible {
    box-shadow: $shadow--medium;
    outline: 2px solid $color--accent--light;
  }
  &:active {
    box-shadow: $shadow--inset--small;
    outline: none;
  }
  &--primary {
    background: $color--primary--lightest;
    border-color: $color--primary;
    :global {
      a {
        color: $color--primary;
      }
    }
    .text {
      color: $color--primary;
      fill: $color--primary;
    }
    &:focus-visible {
      background-color: $color--primary--lightest;
      border-color: $color--primary--light;
      .text {
        color: $color--primary--light;
        fill: $color--primary--light;
        :global {
          a {
            color: $color--primary--light;
          }
        }
      }
    }
    &:hover {
      background: $color--primary;
      border-color: $color--primary--light;
      .text {
        color: $color--primary--lightest;
        fill: $color--primary--lightest;
        :global {
          a,
          a:hover {
            color: $color--primary--lightest;
            text-decoration: none;
          }
        }
      }
    }
    &:active {
      background: $color--primary--dark;
      .text {
        color: $color--primary--lightest;
        fill: $color--primary--lightest;
        :global {
          a,
          a:hover {
            color: $color--primary--lightest;
            text-decoration: none;
          }
        }
      }
    }
  }
  &.button--accent {
    background: $color--accent;
    border-color: $color--accent;
    :global {
      a {
        color: $color--accent--lightest;
      }
    }
    .text {
      color: $color--accent--lightest;
      fill: $color--accent--lightest;
    }
    &:hover {
      background: $color--accent--light;
      .text {
        color: $color--accent--lightest;
        fill: $color--accent--lightest;
        :global {
          a,
          a:hover {
            color: $color--accent--lightest;
            text-decoration: none;
          }
        }
      }
    }
    &:focus-visible,
    &:active {
      background: $color--accent--dark;
      .text {
        color: $color--accent--lightest;
        fill: $color--accent--lightest;
        :global {
          a,
          a:hover {
            color: $color--accent--lightest;
            text-decoration: none;
          }
        }
      }
    }
    &:disabled {
      @include disabled-styles;
    }
  }
  &--secondary {
    background: $color--primary--lightest;
    border-color: $color--gray--light;
    .text {
      color: $color--primary;
      fill: $color--primary;
    }
    &:hover {
      background: $color--white;
      box-shadow: $shadow--small;
      .text {
        color: $color--gray--darkest;
        fill: $color--gray--darkest;
        :global {
          a,
          a:hover {
            color: $color--gray--darkest;
            text-decoration: none;
          }
        }
      }
    }
    &:focus-visible,
    &:active {
      box-shadow: $shadow--inset--small;
    }
    &:disabled {
      @include disabled-styles;
    }
  }
  &.button--danger {
    background: $color--negative;
    border-color: $color--negative;
    .text {
      color: $color--negative--lightest;
      fill: $color--negative--lightest;
    }
    &:hover {
      background: $color--negative--light;
      .text {
        color: $color--white;
        fill: $color--white;
        :global {
          a,
          a:hover {
            color: $color--white;
            text-decoration: none;
          }
        }
      }
    }
    &:focus-visible,
    &:active {
      background: $color--negative--dark;
      .text {
        color: $color--white;
        fill: $color--white;
        :global {
          a,
          a:hover {
            color: $color--white;
            text-decoration: none;
          }
        }
      }
    }
    &:disabled {
      @include disabled-styles;
    }
  }
  &.button--icon {
    border-radius: 50%;
    padding: 0;
    height: 2.6rem;
    width: 2.6rem;
    justify-content: center;
    align-items: center;
    &.button--secondary:hover {
      box-shadow: $shadow--medium;
    }
  }
  svg {
    position: relative;
  }
}
