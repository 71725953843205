@import 'styles/system/variables';

.item {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: $spacing--medium;
  width: 100%;
  border-bottom: $border--small;

  &:last-child:not(.border) {
    border-bottom: none;
  }

  @include layout--small {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.label {
  padding: 1em 0;
  min-width: 100px;
}

.value {
  display: flex;
  align-items: center;
  & > svg {
    padding: 1em;
  }
}
