@import 'styles/system/variables';

.label-text {
  text-transform: uppercase;
  color: $color--primary;
  &--section {
    font-family: $font-family--display;
    font-weight: $font-weight--bold;
    font-size: $font-size--highlight;
    line-height: $line-height--display;
  }
  &--detail {
    font-family: $font-family--copy;
    font-weight: $font-weight--medium;
    font-size: $font-size--copy;
    line-height: $line-height--copy;
    color: $color--gray--dark;
  }
  &--accent {
    color: $color--accent;
  }
  svg {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}
