@import 'styles/system/variables';

.container {
  padding: $spacing--small;
  &--row {
    display: flex;
    gap: 0.75rem;
  }
  &--justify-center {
    justify-content: center;
  }
  &--justify-start {
    justify-content: flex-start;
  }
  &--justify-end {
    justify-content: flex-end;
  }
  &--justify-space-between {
    justify-content: space-between;
  }
  &--collapsed {
    padding: $spacing--small--collapsed;
  }
}
