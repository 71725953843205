@import 'styles/system/variables';

@mixin checkbox {
  content: '';
  display: inline-block;
  position: relative;
  flex: none;
  z-index: $z-index--background;
  width: 1.5em;
  height: 1.4em;
  border: 2px solid $color--gray--dark;
  border-radius: 2px;
  background: $color--primary--lightest;
}

@mixin checkbox--checked {
  background: $color--accent--light;
  border-color: $color--accent;
}

@mixin checkbox--disabled {
  background: $color--gray--light;
  border-color: $color--gray;
}

@mixin checkmark {
  z-index: $z-index--control-mark;
  position: absolute;
  content: '';
  display: inline-block;
  align-self: center;
  width: 0.6em;
  height: 0.35em;
  margin-bottom: 0.2em;
  border-left: 2px solid;
  border-bottom: 2px solid;
  border-color: $color--accent--lightest;
  transform: rotate(-45deg);
}

@mixin checkmark--disabled {
  border-color: $color--gray;
}

.checkbox {
  position: relative;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 0.2rem;
  margin-bottom: 0.5rem;
  &::after,
  &::before {
    box-sizing: border-box;
  }
  &:hover {
    cursor: pointer;
  }
  &--label-start {
    justify-content: flex-start;
    &:focus-within {
      &::after {
        box-shadow: $shadow--focused;
      }
    }
    &::after {
      @include checkbox;
    }
    &.checkbox--checked {
      &::after {
        @include checkbox--checked;
      }
      &::before {
        @include checkmark;
        top: 0.5rem;
        right: 0.65em;
      }
    }
    &.checkbox--disabled {
      cursor: not-allowed;
      &::after {
        @include checkbox--disabled;
      }
      &.checkbox--checked {
        &::before {
          @include checkmark--disabled;
        }
      }
    }
  }
  &--label-end {
    &:focus-within {
      &::before {
        box-shadow: $shadow--focused;
      }
    }
    &::before {
      @include checkbox;
    }
    &.checkbox--checked {
      &::before {
        @include checkbox--checked;
      }
      &::after {
        @include checkmark;
        top: 0.5rem;
        left: 0.65rem;
      }
    }
    &.checkbox--disabled {
      cursor: not-allowed;
      &::before {
        @include checkbox--disabled;
      }
      &.checkbox--checked {
        &::after {
          @include checkmark--disabled;
        }
      }
    }
  }
  &__input {
    display: inline-block;
    opacity: 0;
    margin: 0;
  }
  &__label {
    line-height: 1.35;
  }
}
