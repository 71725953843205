@import 'styles/system/variables';

.table {
  width: 100%;
  &__header {
    padding: $spacing--medium;
    border-bottom: $border--small;
    text-align: left;
    &--center {
      text-align: center;
    }
    &--right {
      text-align: right;
    }
  }
}

.header {
  text-transform: uppercase;
}
