@import 'styles/system/variables';

@mixin radio {
  content: '';
  display: inline-block;
  position: relative;
  flex: none;
  z-index: $z-index--background;
  width: 1.5em;
  height: 1.5em;
  border: 2px solid $color--gray--dark;
  border-radius: 0.75em;
  background: $color--primary--lightest;
}

@mixin radio--checked {
  background: $color--accent--lightest;
  border-color: $color--accent;
}

@mixin radio--disabled {
  background: $color--gray--light;
  border-color: $color--gray;
}

@mixin fill {
  z-index: $z-index--control-mark;
  display: inline-block;
  position: absolute;
  content: '';
  width: 0.8em;
  height: 0.8em;
  border-radius: 0.4em;
  background: $color--accent--light;
}

@mixin fill--disabled {
  background: $color--gray;
}

.radio {
  position: relative;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  &::after,
  &::before {
    box-sizing: border-box;
  }
  &:hover {
    cursor: pointer;
  }
  &--label-start {
    &:focus-within {
      &::after {
        box-shadow: $shadow--focused;
      }
    }
    &::after {
      @include radio;
    }
    &.radio--checked {
      &::after {
        @include radio--checked;
      }
      &::before {
        @include fill;
        top: 0.35em;
        right: 0.35em;
      }
    }
    &.radio--disabled {
      cursor: not-allowed;
      &::after {
        @include radio--disabled;
      }
      &.radio--checked {
        &::before {
          @include fill--disabled;
        }
      }
    }
  }
  &--label-end {
    &:focus-within {
      &::before {
        box-shadow: $shadow--focused;
      }
    }
    &::before {
      @include radio;
    }
    &.radio--checked {
      &::before {
        @include radio--checked;
      }
      &::after {
        @include fill;
        top: 0.35em;
        left: 0.35em;
      }
    }
    &.radio--disabled {
      cursor: not-allowed;
      &::before {
        @include radio--disabled;
      }

      &.radio--checked {
        &::after {
          @include fill--disabled;
        }
      }
    }
  }
  &__input {
    display: inline-block;
    opacity: 0;
    margin: 0;
  }
  &__label {
    line-height: 1.35;
  }
}

.radio-container {
  justify-content: flex-start;
  align-items: flex-start;

  &--column {
    flex-direction: column;
  }
  &--row {
    flex-direction: row;
  }
}
