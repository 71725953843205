@import 'styles/system/variables';

.menu {
  position: absolute;
  width: 250px;
  list-style: none;
  padding-bottom: 0.5rem;
  background-color: $color--white;
  border: $border--small;
  border-radius: $border-radius--large;
  box-shadow: $shadow--medium;
  margin-top: 0.5rem;
  max-height: 350px;
  overflow: auto;
  z-index: $z-index--dropdown;
  &__anchor {
    position: relative;
  }
  &__item {
    padding: 0.5rem 1rem;
    color: $color--gray--darkest;
    &--disabled {
      padding: 0.5rem 1rem;
      color: $color--gray;
      &:hover {
        cursor: not-allowed;
      }
    }
    &--active {
      padding: 0.5rem 1rem;
      color: $color--primary;
      background-color: $color--primary--lightest;
    }
    &:hover {
      color: $color--primary;
      background-color: $color--primary--lightest;
    }
  }
  &--in-modal {
    z-index: $z-index--dropdown--in-modal;
  }
}