@import 'styles/system/variables';

.tab {
  &__container {
    background-color: $color--background;
    min-height: 50vh;
    border-radius: $border-radius--medium;
    box-shadow: $shadow--medium;
    overflow: hidden;
  }
  &__toolbar {
    overflow-x: auto;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    background: $color--background--light;
    border-bottom: $border--medium;
    &__item {
      padding: 1rem 2rem 0.75rem;
      min-width: 200px;
      white-space: nowrap;
      @include layout--small {
        min-width: 33vw;
      }
      text-align: center;
      color: $color--gray--dark;
      border-bottom: 2px solid transparent;
      &:hover,
      &:focus {
        background-color: $color--primary--lightest;
        border-color: $color--primary--light;
        @include transition-in;
        cursor: pointer;
      }
      &--active,
      &--active:hover {
        color: $color--accent;
        border-color: $color--accent;
      }
      &--disabled,
      &--disabled:hover,
      &--disabled:focus {
        color: $color--gray;
        background-color: $color--gray--light;
        border-bottom: 2px solid $color--gray--light;
        cursor: not-allowed;
        box-shadow: $shadow--inset--small;
      }
      &__label {
        position: relative;
        &--alert::after {
          display: block;
          position: absolute;
          top: 0;
          right: -15px;
          content: '';
          height: 6px;
          width: 6px;
          border-radius: 50%;
          background-color: $color--negative;
        }
      }
    }
  }
  &__body {
    position: relative;
    display: flex;
    background-color: $color--background;
  }
  &__panel {
    width: 100%;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transform: translateX(20%);
    &--active {
      position: relative;
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
      @include transition-in;
    }
  }
}
