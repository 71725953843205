$shadow--small: 0px 0px 0px -2px rgba(0, 0, 0, 0.12),
  0px 1px 1px rgba(0, 0, 0, 0.06);
$shadow--medium: 0px 0px 0px 1px rgba(0, 0, 0, 0.12),
  0px 8px 8px -2px rgba(0, 0, 0, 0.12);
$shadow--large: 0px 0px 0px 1px rgba(0, 0, 0, 0.12),
  0px 20px 15px rgba(0, 0, 0, 0.12);

$shadow--inset--small: inset 1px 3px 4px rgba(72, 89, 115, 0.12);

$shadow--focused: 0 0 2px 1px $color--accent--light;
