@import 'styles/system/variables';

.container {
  width: 100%;
}

.field {
  color: $color--gray--darkest;
  background-color: $color--primary--lightest;
  border: 1px solid $color--gray--light;
  border-radius: $border-radius--medium;
  padding: 0.25rem 0.5rem;
  width: 100%;
  &:focus-visible {
    outline: 1px solid $color--primary--light; 
    box-shadow: $shadow--small;
  }
  &[disabled] {
    color: $color--gray;
    background-color: $color--background--dark;
    &:hover {
      cursor: not-allowed;
    }
  }
  &--error {
    border: 1px solid $color--negative--light;
    &:focus-visible {
      outline: 1px solid $color--negative; 
    }
  }
  &--with-icon {
    padding-left: 2.25rem;
  }
  &__wrapper {
    position: relative;
  }
  &__icon {
    position: absolute;
    left: 0.75rem;
    top: 0;
    bottom: 0;
    margin: auto;
    color: $color--gray--dark;
  }
}

.label {
  padding-left: 0.125rem;
  padding-bottom: 0.125rem;
  color: $color--gray--dark;
  &--disabled {
    color: $color--gray;
  }
  &--error {
    color: $color--negative;
  }
}

.hint {
  padding: 0 0.25rem;
  &--error {
    color: $color--negative;
  }
}
