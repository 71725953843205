@import 'styles/system/variables';

.cell {
  padding: $spacing--medium;

  &--right {
    text-align: right;
  }

  &--center {
    text-align: center;
  }
}
