@import 'styles/system/variables';
@import 'styles/system/partials/_animation';

.body {
  margin: $spacing--medium;
  max-height: 300px;
  background-color: $color--background;
  border: $border--small;
  border-radius: $border-radius--small;
  box-shadow: $shadow--inset--small;
  overflow: auto;
  @include transition-in(max-height, 300ms);
  &--closed {
    border-color: transparent;
    max-height: 0;
    @include transition-out(max-height, 300ms);
  }
}
