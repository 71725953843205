@import 'styles/system/variables';

.container {
  position: relative;
  display: inline-flex;
  justify-content: center;
  .tooltip {
    visibility: hidden;
    z-index: $z-index--tooltip;
    opacity: 0;
    position: absolute;
    transform: translateY(50%);
    margin-left: auto;
    margin-right: auto;
    width: max-content;
    height: max-content;
    color: $color--brand--lightest;
    background-color: $color--brand--darkest;
    border-radius: $border-radius--large;
    padding: 0.125rem 1rem;
    box-shadow: $shadow--medium;
    @include transition-out(opacity, 200ms, transform, 200ms);
    &--right {
      left: calc(100% + 1rem);
      top: 50%;
    }
    &--left {
      right: calc(100% + 1rem);
      top: 50%;
    }
    &--top {
      top: -40px;
    }
    &--bottom {
      bottom: -90px;
    }
    &--hidden {
      display: none;
    }
  }
  &:hover {
    cursor: pointer;
    .tooltip {
      visibility: visible;
      opacity: 1;
      transform: translateY(-50%);
      @include transition-in;
      &:hover {
        display: none;
      }
    }
  }
}
