@import 'styles/system/variables';
@import 'styles/system/partials/_helpers';
@import 'styles/system/partials/_animation';

@keyframes dialog-backdrop-in {
  from {background: rgba(0, 0, 0, 0);}
  to {background: rgba(0, 0, 0, 0.5);}
}
@keyframes dialog-backdrop-out {
  from {background: rgba(0, 0, 0, 0.5);}
  to {background: rgba(0, 0, 0, 0);}
}

.dialog {
  display: block;
  position: absolute;
  overflow: visible;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0 !important;
  border: 0;
  width: 100%;
  @include transition-out(all);
  &::backdrop {
    display: block;
    animation: dialog-backdrop-out 400ms $ease-out--quint 0s 1;
    background: rgba(0, 0, 0, 0);
  }
  &--visible {
    @include transition-out(all, 650ms);
    &::backdrop {
      animation: dialog-backdrop-in 650ms $ease-out--quint 0s 1;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  body:has(&[open]) {
    overflow: hidden;
    scrollbar-gutter: stable;
  }
}

.container {
  display: flex;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100vh;
  width: 100%;
  &--visible {
    display: flex;
  }
}

.modal {
  display: flex;
  visibility: visible;
  flex-direction: column;
  position: fixed;
  z-index: $z-index--modal;
  max-height: 90vh;
  min-width: 35vw;
  max-width: 80vw;
  background: $color--white;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
  transform: translateY(80%);
  pointer-events: none;
  opacity: 0;
  @include transition-out(all, 300ms);
  &--visible {
    pointer-events: auto;
    transform: translateY(0);
    opacity: 1;
    @include transition-out(all, 650ms);
  }
  &--overflow {
    overflow: visible;
    .content {
      overflow: visible !important;
    }
  }
}

.content {
  flex: 0;
}
.content {
  min-height: 100px;
  overflow: auto;
}
