@import 'styles/system/variables';

.text {
  @include font--action;
  color: $color--primary;
  &--large {
    font-family: $font-family--display;
    font-weight: $font-weight--bold;
    font-size: $font-size--highlight;
    color: $color--primary;
  }
  svg {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}
