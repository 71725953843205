@import 'styles/system/variables';
@import 'styles/system/partials/_animation';

.accordion {
  border: $border--small;
  border-radius: $border-radius--small;
  background-color: $color--background;
  &:first-child {
    border-top: none;
  }
  &__panel {
    border-top: $border--small;
    &__header {
      padding: $spacing--medium;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__label {
        display: flex;
        align-items: center;
        gap: 1rem;
        &__icon {
          color: $color--primary--light;
        }
      }
    }
    &__content {
      max-height: 0;
      overflow: hidden;
      border-top: thin solid $color--gray--lightest;
      background-color: $color--background--lightest;
      box-shadow: $shadow--inset--small;
      &--active {
        max-height: 500px;
        overflow: auto;
      }
    }
    &__chevron {
      color: $color--gray--dark;
      @include transition-in();
      &--active {
        transform: rotate(90deg);
        @include transition-in();
      }
    }
  }
}
