@import 'styles/system/variables';

@mixin switch {
  content: '';
  display: inline-block;
  position: relative;
  flex: none;
  z-index: $z-index--background;
  width: 3em;
  height: 1.5em;
  border: 0.5em solid $color--gray--light;
  border-radius: 0.75em;
  background: $color--primary--lightest;
}

@mixin switch--checked {
  background: $color--accent--lightest;
  border-color: $color--accent;
}

@mixin switch--disabled {
  background: $color--gray;
  border-color: $color--gray--light;
}

@mixin fill {
  z-index: $z-index--control-mark;
  display: inline-block;
  position: absolute;
  content: '';
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  border: 2px solid $color--primary--lightest;
  background: $color--primary--light;
  box-shadow: $shadow--medium;
}

@mixin fill--checked {
  background: $color--accent--light;
}

.switch {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  &::after,
  &::before {
    box-sizing: border-box;
  }
  &:hover {
    cursor: pointer;
  }
  &--label-start {
    &:focus-within {
      &::after {
        box-shadow: $shadow--focused;
      }
    }
    &::after {
      @include switch;
    }
    &::before {
      transition: right 250ms $ease-in--quint;
      @include fill;
      right: 0.3em;
    }
    &.switch--checked {
      &::after {
        @include switch--checked;
      }
      &::before {
        @include fill--checked;
        right: 1.75em;
      }
    }
    &.switch--disabled {
      cursor: not-allowed;
      &::after {
        @include switch--disabled;
      }
      &::before {
        box-shadow: none;
      }
      &:not(.switch--checked)::before {
        background: $color--gray;
      }
    }
  }
  &--label-end {
    transition: left $ease-in--quint;
    &:focus-within {
      &::before {
        box-shadow: $shadow--focused;
      }
    }
    &::before {
      @include switch;
    }
    &::after {
      transition: left 250ms $ease-in--quint;
      @include fill;
      left: 0.3em;
    }
    &.switch--checked {
      &::before {
        @include switch--checked;
      }
      &::after {
        @include fill--checked;
        left: 1.75em;
      }
    }
    &.switch--disabled {
      cursor: not-allowed;
      &::before {
        @include switch--disabled;
      }
      &::after {
        box-shadow: none;
      }
      &:not(.switch--checked)::after {
        background: $color--gray;
      }
    }
  }
  &__input {
    display: inline-block;
    opacity: 0;
    margin: 0;
  }
}
