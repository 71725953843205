@import 'styles/system/variables';

.container {
  position: sticky;
  z-index: $z-index--sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing--medium;
  box-shadow: $shadow--medium;
  animation: fade-in 300ms $ease-in--quint;
  &--success {
    background-color: $color--positive;
    .text,
    .icon {
      color: $color--positive--lightest;
    }
  }
  &--neutral {
    background-color: $color--warn;
    .text,
    .icon {
      color: $color--warn--darkest;
    }
  }
  &--error {
    background-color: $color--negative;
    .text,
    .icon {
      color: $color--negative--lightest;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
}

.tools {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@keyframes fade-in {
  from { opacity: 0; transform: translateY(-100%); }
  to   { opacity: 1; transform: translateY(0%); }
}
