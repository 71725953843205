@import 'styles/system/variables';

.wrapper {
  width: 100%;
}

.spacer {
  flex: 0;
  margin: auto 0;
  text-align: center;
  min-width: 20px;
  height: 100%;
  padding-left: 0.25rem;
}

.container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  color: $color--gray--darkest;
  background-color: $color--primary--lightest;
  border: 1px solid $color--gray--light;
  border-radius: $border-radius--medium;
  padding-right: 3rem;
  width: 100%;
  overflow: hidden;
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }
  input[type="date"]::placeholder {
    color: $color--gray--dark;
  }
  &--range {
    .field {
      text-align: center;
    }
  }
  &--disabled {
    background-color: $color--background--dark;
    .field {
      &__caret {
        background-color: $color--background--dark;
      }
      &__icon {
        color: $color--gray;
      }
    }
  }
  &--error {
    border: 1px solid $color--negative--light;
    &:focus-visible {
      outline: 1px solid $color--negative;
    }
  }
}

.field {
  position: relative;
  flex: 0 1 100px;
  appearance: none;
  color: $color--gray--darkest;
  background-color: $color--primary--lightest;
  border: 0px;
  border-bottom: 2px solid transparent;
  padding-top: 0.25rem;
  overflow: hidden;
  &:focus-visible,
  &--focused {
    outline: 0px;
    border-bottom: 2px solid $color--accent--light;
  }
  &[disabled] {
    color: $color--gray;
    background-color: $color--background--dark;
    &:hover {
      cursor: not-allowed;
    }
  }
  &--placeholder {
    color: $color--gray--dark
  }
  &--with-icon {
    margin-left: 2.25rem;
  }
  &--first-date {
    text-align: right;
    margin-left: 2rem;
  }
  &--second-date {
    text-align: left;
  }
  &__caret {
    position: absolute;
    right: 0.5rem;
    background-color: $color--primary--lightest;
    top: 0.5rem;
    color: $color--gray--dark;
    :global {
      svg {
        fill: $color--gray--dark;
      }
    }
  }
  &__clearable {
    position: absolute;
    cursor: pointer;
    right: 1.5rem;
    top: 0.5rem;
    color: $color--gray--dark;
    :global {
      svg {
        fill: $color--gray--dark;
      }
    }
  }
  &__icon {
    position: absolute;
    z-index: $z-index--control-mark;
    left: 0.75rem;
    top: 0.6rem;
    margin: auto;
    color: $color--gray--dark;
    :global {
      svg {
        fill: $color--gray--dark;
      }
    }
  }
  &__menu {
    position: absolute;
    width: 100%;
    list-style: none;
    padding: 0.5rem 0;
    background-color: $color--white;
    border: $border--small;
    border-radius: $border-radius--large;
    box-shadow: $shadow--medium;
    margin-top: 0.5rem;
    &__anchor {
      position: relative;
    }
    &__item {
      padding: 0.5rem 1rem;
      color: $color--gray--darkest;
      &--disabled {
        padding: 0.5rem 1rem;
        color: $color--gray;
        &:hover {
          cursor: not-allowed;
        }
      }
      &--active {
        padding: 0.5rem 1rem;
        color: $color--primary;
        background-color: $color--primary--lightest;
      }
      &:hover {
        color: $color--primary;
        background-color: $color--primary--lightest;
        cursor: pointer;
      }
    }
  }
}

.calendar {
  position: absolute;
  z-index: $z-index--dropdown;
  top: 0;
  left: 0;
  margin: 0.5rem 0;
  width: 400px;
  color: $color--gray--darkest;
  background-color: $color--white;
  border-radius: $border-radius--medium;
  box-shadow: $shadow--medium;
  &__month-year-header {
    display: flex;
    justify-content: space-between;
    &--navigate-buttons {
      display: flex;
      margin: 1rem 0.75rem;
    }
  }
  &__dates {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-items: center;
    grid-row-gap: 1rem;
    padding-bottom: 0.5rem;
    overflow: hidden;
    &__day {
      color: $color--primary;
      background-color: $color--white;
      border: thin solid transparent;
      border-radius: $border-radius--large;
      height: 40px;
      width: 40px;
      &:hover {
        color: $color--white;
        background-color: $color--accent--light;
        cursor: pointer;
        .prevMonth,
        .nextMonth,
        .currMonth {
          color: $color--white !important;
        }
      }
      .prevMonth,
      .nextMonth {
        color: $color--gray !important;
      }
      .currMonth {
        color: $color--primary !important;
      }
      &--is-today {
        .prevMonth,
        .nextMonth,
        .currMonth {
          color: $color--accent !important;
        }
      }
      &--active {
        position: relative;
        z-index: $z-index--control-mark;
        color: $color--white;
        background-color: $color--primary--light;
        cursor: pointer;
        .prevMonth,
        .nextMonth,
        .currMonth {
          color: $color--white !important;
        }
      }
      &--between {
        position: relative;
        z-index: $z-index--background;
        background-color: $color--primary--lightest;
        width: 140%;
        padding: 0;
        border-top: thin solid $color--gray--light;
        border-bottom: thin solid $color--gray--light;
        border-radius: 0;
        &:hover {
          background-color: $color--primary--lightest;
          color: $color--accent;
          border-radius: $border-radius--large;
          .prevMonth,
          .nextMonth,
          .currMonth {
            color: $color--accent !important;
          }
        }
      }
      &:focus {
        outline: none;
      }
      &[disabled],
      &[disabled]:hover {
        color: $color--gray;
        background-color: transparent;
        .prevMonth,
        .nextMonth,
        .currMonth {
          color: $color--gray !important;
        }
        cursor: not-allowed;
      }
    }
  }
  &--in-modal {
    z-index: $z-index--dropdown--in-modal;
  }
}

.label {
  padding-left: 0.125rem;
  padding-bottom: 0.125rem;
  color: $color--gray--dark;
  &--disabled {
    color: $color--gray;
  }
  &--error {
    color: $color--negative;
  }
}

.hint {
  padding: 0 0.25rem;
  &--error {
    color: $color--negative;
  }
}

.month-picker {
  margin: 1rem 0.5rem;
  padding: 0.25rem 0.75rem;
  border: thin solid transparent;
  text-decoration: underline;
  .month-picker__label {
    color: $color--primary--darkest;
  }
  &:hover {
    background-color: $color--primary--lightest;
    border: $border--small;
    border-radius: $border-radius--medium;
    box-shadow: $shadow--small;
    text-decoration: none;
    cursor: pointer;
  }
  &__chevron {
    display: flex;
    margin: auto;
    padding: 0.25rem 0.5rem;
    color: $color--gray--dark;
    border: thin solid transparent;
    &:hover {
      background-color: $color--primary--lightest;
      border: $border--small;
      border-radius: $border-radius--small;
      box-shadow: $shadow--small;
      cursor: pointer;
    }
  }
}

.accordion {
  width: 100%;
  height: 400px;
  overflow: scroll;
  padding: 1rem;
  &__section {
    &__header {
      width: 100%;
      color: $color--primary;
      background-color: $color--primary--lightest;
      border: thin solid $color--gray--light;
      &:focus {
        outline: none;
      }
    }
    &__body {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-items: center;
      grid-row-gap: 1rem;
      padding: 0.5rem 0;
    }
  }
  &__month {
    color: $color--primary;
    background-color: $color--white;
    border: thin solid transparent;
    border-radius: $border-radius--large;
    height: 50px;
    width: 50px;
    &:hover {
      color: $color--white;
      background-color: $color--accent--light;
      cursor: pointer;
    }
    &--active {
      color: $color--white;
      background-color: $color--primary--light;
      cursor: pointer;
    }
  }
}

.presets {
  padding-bottom: 0.5rem;
  background-color: $color--background;
  border-top: $border--small;
  border-radius: 0 0 $border-radius--medium $border-radius--medium;
  &__header {
    margin: 1rem;
  }
  &__button-block {
    display: flex;
    flex-flow: row wrap;
    margin: 1rem;
    gap: 1rem;
  }
}
