@import 'styles/system/variables';

.dialog {
  position: absolute;
  width: 250px;
  list-style: none;
  background-color: $color--white;
  border: $border--small;
  border-radius: $border-radius--large;
  box-shadow: $shadow--medium;
  margin-top: 0.5rem;
  max-height: 350px;
  overflow: auto;
}
